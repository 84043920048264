<template>
  <div class="tooltip-container" @click="showTooltip = !showTooltip">
    <span
      :aria-hidden="!title"
      :aria-label="title"
      class="material-design-icon image-icon tooltip-icon"
      role="img"
      v-bind="$attrs"
      @click="$emit('click', $event)">
      <svg
        :fill="fillColor"
        class="material-design-icon__svg"
        :width="size"
        :height="size"
        viewBox="0 0 24 24">
        <path
          d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </svg>
    </span>
    <div v-if="showTooltip" class="tooltip">
      {{ tooltip }}
      <CloseCircleOutline
        title="Close"
        class="tooltip-close"
        :size="18"
        @click="showTooltip = !showTooltip" />
    </div>
  </div>
</template>

<script>
  export default {
    name: "ImageIcon",
    data() {
      return {
        showTooltip: false,
      };
    },
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
      tooltip: {
        type: String,
        default: "",
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tooltip-container {
    display: inline-block;
    position: relative;
  }

  .tooltip-icon {
    cursor: pointer;
  }

  .tooltip {
    display: block;
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    padding: 0.9rem 0.9rem 0.7rem 0.7rem;
    width: 15rem;
    bottom: 2rem;
    left: -7rem;
    font-size: 0.9rem;
  }

  .tooltip .close-circle-outline-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
  }

  .tooltip::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
</style>
