import store from "@/store";
import * as apiPrivate from "@tucktrucks/platform-base-private";
import $cookies from "vue-cookies";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    bearerToken: null,
    refreshToken: null,
    username: null,
    client: null,
  },

  mutations: {
    INSTANTIATE_CLIENT(state) {
      if (state.client === null) {
        state.client = new apiPrivate.Client();
        state.client.domain = process.env.VUE_APP_PRIVATE_API_DOMAIN;
        state.client.sessionId = window.sessionId;
        state.client.fingerprint = window.fingerprint;

        state.client.onRefresh = (tokens) => {
          state.bearerToken = tokens.bearer;
          state.refreshToken = tokens.refresh;

          $cookies.set("bearerToken", tokens.bearer);
          $cookies.set("refreshToken", tokens.refresh);

          window.log.info("[apiPrivate.js] Refreshed token successfully.");
        };

        state.client.onRefreshFailed = () => {
          state.bearerToken = null;
          state.refreshToken = null;

          $cookies.remove("bearerToken");
          $cookies.remove("refreshToken");

          window.log.info("[apiPrivate.js] Token refresh failed.");

          router.push("/login");
        };

        // Catches all errors, does not include response info.
        //state.client.onError = (error) => { window.log.error("[api]", error); }

        // Catches axios errors, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onAxiosError = (status, error) => { window.log.error("[api.axios]", status, error) }

        // Catches axios from 400-499, includes status and response - Note, this will include intentional 404 responses etc.
        state.client.onClientError = (status /*error*/) => {
          //window.log.warn("[api.client]", status, error)

          if (status == 401) {
            if (router.currentRoute._value.fullPath != "/login") {
              router.push("/login");
            }
          }
        };

        // Catches axios from 500-599, includes status and response
        //state.client.onServerError = (status, error) => { window.log.error("[api.server]", status, error); };

        // Catches network errors, i.e. server ignored request, includes response - this can be used to detect lack of connectivity
        state.client.onNetworkError = (error) => {
          store.dispatch("networkStatus/onNetworkError", error);
        };

        store.dispatch("apiPrivateCustomers/instantiate");
        store.dispatch("apiPrivateDeliveries/instantiate");
        // store.dispatch("apiPrivateComponents/instantiate");
      }

      const cookieBearerToken = $cookies.get("bearerToken");
      const cookieRefreshToken = $cookies.get("refreshToken");

      state.client.useTokens(cookieBearerToken, cookieRefreshToken);
    },

    SET_TOKENS(state, payload) {
      state.client.useTokens(payload.bearerToken, payload.refreshToken);

      state.bearerToken = payload.bearerToken;
      state.refreshToken = payload.refreshToken;
      state.username = payload.username;

      $cookies.set("bearerToken", payload.bearerToken);
      $cookies.set("refreshToken", payload.refreshToken);
      $cookies.set("username", payload.username);
    },

    CLEAR_TOKENS(state) {
      state.bearerToken = null;
      state.refreshToken = null;
      state.username = null;

      $cookies.remove("bearerToken");
      $cookies.remove("refreshToken");
      $cookies.remove("username");
    },
  },

  actions: {
    instantiateClient({ commit }) {
      commit("INSTANTIATE_CLIENT");
    },

    clearTokens({ commit }) {
      commit("CLEAR_TOKENS");
    },

    setTokens({ commit }, payload) {
      commit("CLEAR_TOKENS");
      commit("SET_TOKENS", payload);
    },

    login({ state, commit }, credentials) {
      const login = new apiPrivate.ApiLogon({
        username: credentials.username,
        password: credentials.password,
      });

      return state.client.endpoints.tokens
        .getBearerToken(login)
        .then(function (response) {
          if (response.status === 200) {
            let payload = {
              bearerToken: response.data.data.bearer,
              refreshToken: response.data.data.refresh,
              username: login.username,
            };

            commit("SET_TOKENS", payload);
            return response;
          }

          return Promise.reject("Login failed.");
        });
    },

    logout({ commit }) {
      commit("CLEAR_TOKENS");
    },
  },
};
