import { createStore } from "vuex";

import networkStatus from "@/store/networkStatus";

import delivery from "@/store/public/delivery";

import apiPublic from "@/store/public/apiPublic";

import apiPublicItems from "@/store/public/repos/apiPublicItems";
import apiPublicEvents from "@/store/public/repos/apiPublicEvents";
import apiPublicOrders from "@/store/public/repos/apiPublicOrders";
import apiPublicServices from "@/store/public/repos/apiPublicServices";
import apiPublicVendors from "@/store/public/repos/apiPublicVendors";

import apiPrivate from "@/store/private/apiPrivate";

import apiPrivateDeliveries from "@/store/private/repos/apiPrivateDeliveries";
import apiPrivateCustomers from "@/store/private/repos/apiPrivateCustomers";

import today from "@/store/delivery/today";
import deliveries from "@/store/delivery/deliveries";
import coordinates from "@/store/delivery/coordinates";
import routes from "@/store/delivery/routes";

//import unsaved from "@/store/unsaved";

export default createStore({
  state: {
    locale: "en-GB",
    currency: "GBP",
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    networkStatus,

    apiPublic,
    apiPublicItems,
    apiPublicEvents,
    apiPublicOrders,
    apiPublicServices,
    apiPublicVendors,

    apiPrivate,
    apiPrivateDeliveries,
    apiPrivateCustomers,

    delivery,
    today,
    deliveries,
    coordinates,
    routes,

    //unsaved,
  },
});
