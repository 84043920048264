import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    component: () => import("@/views/Main.vue"),
  },
  {
    path: "/delivering?active=true",
    component: () => import("@/views/Delivering.vue"),
  },
  {
    path: "/delivering",
    component: () => import("@/views/Delivering.vue"),
  },
  {
    path: "/:catchAll(.*)",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/login",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/error",
    name: "Error",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/Error.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // Allow hash links to go through unmodified
    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    window.log.info(
      `[🧭] ${from.path} ➡️ ${to.path} [${savedPosition?.left ?? 0}, ${
        savedPosition?.top ?? 0
      }]`
    );

    // always scroll to top
    return { top: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    // doesn't require auth
    window.log.info("[🧭] Anonymous endpoint.");
  } else {
    // make sure the singleton client exists
    window.log.info("[🧭] Endpoint requires authentication.");

    if (!store.state.apiPrivate.client.isConnected()) {
      store.dispatch("apiPrivate/clearTokens");
      router.push("/login");
    }

    let eventId = store.getters["today/eventId"];

    if (eventId == null || eventId == 0) {
      await store.dispatch("today/downloadTodaysEvent");
    }
  }

  next();
});

export default router;
