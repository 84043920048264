<template>
  <span
    :aria-hidden="!title"
    :aria-label="title"
    class="material-design-icon image-icon"
    role="img"
    v-bind="$attrs"
    @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24">
      <path
        d="M12,2A2,2 0 0,1 14,4A2,2 0 0,1 12,6A2,2 0 0,1 10,4A2,2 0 0,1 12,2M10.5,7H13.5A2,2 0 0,1 15.5,9V14.5H14V22H10V14.5H8.5V9A2,2 0 0,1 10.5,7Z" />
    </svg>
  </span>
</template>

<script>
  export default {
    name: "ImageIcon",
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
    },
  };
</script>
