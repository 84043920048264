<template>
  <router-view></router-view>
  <transition name="fade">
    <NetworkStatus v-if="hasNetworkError" />
  </transition>
</template>

<script>
  import NetworkStatus from "@/components/NetworkStatus.vue";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {};
    },

    computed: {
      ...mapGetters({
        hasNetworkError: "networkStatus/hasNetworkError",
      }),
    },

    components: {
      NetworkStatus,
    },

    mounted() {},

    errorCaptured(err, vm, info) {
      window.log.error(
        `An unhandled exception was caught on the main App component.\n${err.message}\n`,
        {
          err,
          vm,
          info,
        }
      );

      return false;
    },
  };
</script>

<style>
  body {
    margin: 0;
    font-family: "Roboto", sans-serif;
  }
</style>
