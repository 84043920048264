import { parseLatLng } from "@tucktrucks/core";
import Enumerable from "linq";

export default {
  namespaced: true,

  state: {
    venue: null,
    destinations: [],
    currentDestinationId: null,
  },

  actions: {
    setVenue({ commit }, coordinate) {
      commit("SET_VENUE", coordinate);
    },

    setDestinations({ commit }, deliveries) {
      let destinations = Enumerable.from(deliveries)
        .select((x) => x.destination)
        .distinct()
        .toArray();
      commit("SET_DESTINATIONS", destinations);
    },

    setCurrentDestination({ commit }, destinationId) {
      commit("SET_CURRENT_DESTINATION", destinationId);
    },
  },

  mutations: {
    SET_VENUE(state, coordinate) {
      state.venue = parseLatLng(coordinate);
    },

    SET_DESTINATIONS(state, destinations) {
      destinations.forEach((element) => {
        element.coordinate = parseLatLng(element.coordinate);
      });

      state.destinations = destinations;
    },

    SET_CURRENT_DESTINATION(state, destinationId) {
      state.currentDestinationId = destinationId;
    },
  },

  getters: {
    venue: (state) => state.venue,
    destinations: (state) => state.destinations,
    currentDestinationId: (state) => state.currentDestinationId,
  },
};
