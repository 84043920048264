<template>
  <div class="topbar" @click.prevent="">
    <div class="container row" @click.stop="">
      <h1 class="row">
        <Icon :path="mdiInformation" :size="18" /> Server Unavailable
      </h1>
      <p>
        The server is not currently available, please wait a minute and try
        again. The administrator has been notified.
      </p>
      <i class="fingerprint">{{ now }}</i>
      <button @click="retry">
        <LoadingSpinner v-if="retrying" />
        <span v-if="!retrying">Retry Connection</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { useToast } from "vue-toastification";
  import { LocalDateTime } from "@js-joda/core";
  import LoadingSpinner from "./LoadingSpinner.vue";
  import { mdiInformation } from "@mdi/js";

  export default {
    data() {
      return {
        retrying: false,
        mdiInformation,
      };
    },
    computed: {
      now() {
        return LocalDateTime.now();
      },
    },
    methods: {
      retry() {
        this.retrying = true;

        this.$store.state.apiPublic.client
          .isApiAvailable()
          .then((response) => {
            if (response.status == 200) {
              useToast().info("Network connection restored.");
              this.$store.dispatch("networkStatus/clearNetworkError");
            }
          })
          .catch((error) => {
            this.$store.dispatch("networkStatus/onNetworkError", error);
          })
          .finally(() => {
            this.retrying = false;
          });
      },
    },
    components: { LoadingSpinner },
  };
</script>

<style lang="scss" scoped>
  button {
    width: 100%;
  }

  .fingerprint {
    font-size: 0.7em;
    color: #fff;
  }
  .topbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    background: $col_beta;
    .container {
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 1rem;
    }
    h1 {
      font-size: 1rem;
      color: #fff;
      padding: 0;
      width: initial;
      margin: 0;
      gap: 0.5rem;
    }
    p {
      color: #fff;
      font-size: 0.875rem;
    }
    button {
      background: #fff;
      width: initial;
      height: 36px;
      min-height: auto;
      color: $col_beta;
      font-weight: bold;
    }
  }
</style>
