export default {
  namespaced: true,

  state: {
    networkError: false,
    networkErrorLast: {},
  },

  mutations: {
    ON_NETWORK_ERROR(state, error) {
      state.networkError = true;
      state.networkErrorLast = error;
    },

    CLEAR_NETWORK_ERROR(state) {
      state.networkError = false;
    },
  },

  actions: {
    onNetworkError({ commit }, error) {
      commit("ON_NETWORK_ERROR", error);
    },

    clearNetworkError({ commit }) {
      commit("CLEAR_NETWORK_ERROR");
    },
  },

  getters: {
    hasNetworkError: (state) => {
      return state.networkError;
    },
    getLastNetworkError: (state) => {
      return state.networkErrorLast;
    },
  },
};
