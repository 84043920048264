// Vue
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Import the core-vue package, this will place with public-vue once those packages are in place
import "@tucktrucks/core-vue/dist/style.css";
// ------------------------------------------------------------

// Toast notifications
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Material Icons
import Icon from "./components/Icon";

import AlertCircleOutline from "./components/icons/AlertCircleOutline.vue";
import CheckCircleOutline from "./components/icons/CheckCircleOutline.vue";
import CloseCircleOutline from "./components/icons/CloseCircleOutline.vue";
import PlusCircleOutline from "./components/icons/PlusCircleOutline.vue";
import RefundPartial from "./components/icons/RefundPartial.vue";
import RefundFull from "./components/icons/RefundFull.vue";
import Minus from "./components/icons/Minus.vue";
import UserAccountCircle from "./components/icons/UserAccountCircle.vue";
import MinusCircleOutline from "./components/icons/MinusCircleOutline.vue";
import Play from "./components/icons/Play.vue";
import Information from "./components/icons/Information.vue";
import CheckboxBlankOutline from "./components/icons/CheckboxBlankOutline";
import Stove from "./components/icons/Stove";
import Food from "./components/icons/Food";
import AccountClockOutline from "./components/icons/AccountClockOutline";
import CheckboxMarkedOutline from "./components/icons/CheckboxMarkedOutline";
import BikeFast from "./components/icons/BikeFast";
import Plus from "./components/icons/Plus.vue";
import Car from "./components/icons/Car";
import Pdf from "./components/icons/Pdf";
import LoadingSpinner from "./components/LoadingSpinner";
import CreditCard from "./components/icons/CreditCard";
import Cash from "./components/icons/Cash";
import Menu from "./components/icons/Menu";
import Vendor from "./components/icons/Vendor";
import Phone from "./components/icons/Phone";
import Basket from "./components/icons/Basket";
import FoodOutlined from "./components/icons/FoodOutlined";
import Person from "./components/icons/Person";
import List from "./components/icons/List";
import ChevronUp from "./components/icons/arrows/ChevronUp";
import ChevronDown from "./components/icons/arrows/ChevronDown";
import ArrowRight from "./components/icons/arrows/ArrowRight";
import ArrowLeft from "./components/icons/arrows/ArrowLeft";
import Check from "./components/icons/Check";
import Close from "./components/icons/Close";
import StatusList from "./components/icons/StatusList";
import Bike from "./components/icons/Bike";
import MapMarker from "./components/icons/MapMarker";
import HumanDolly from "./components/icons/HumanDolly";
import PartyPopper from "./components/icons/PartyPopper";

// import SwapHorizontalBold from "vue-material-design-icons/SwapHorizontalBold";
// import ArrowLeftBold from "vue-material-design-icons/ArrowLeftBold";
// import ArrowRightBold from "vue-material-design-icons/ArrowRightBold";
// import Silverware from "vue-material-design-icons/Silverware";
// import Cart from "vue-material-design-icons/Cart";
// import Tire from "@/components/icons/Tire";
// import Autorenew from "vue-material-design-icons/Autorenew";
// import CreditCardOutline from "vue-material-design-icons/CreditCardOutline";
// import TableRow from "vue-material-design-icons/TableRow";
// import TableColumn from "vue-material-design-icons/TableColumn";
// import InformationOutline from "vue-material-design-icons/InformationOutline";

// Vee-Validate
import { Field, Form, ErrorMessage } from "vee-validate";

// Mixins
import formatters from "@/plugins/formatters";
import linq from "@/plugins/linq";

const toastOptions = {
  position: "bottom-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  hideProgressBar: true,
  closeButton: "toast-button",
  icon: true,
};

const app = createApp(App)
  .use(store)
  .use(router)
  .use(formatters)
  .use(linq)
  .use(Toast, toastOptions)

  .component("Icon", Icon)

  .component("AlertCircleOutline", AlertCircleOutline)
  .component("CheckCircleOutline", CheckCircleOutline)
  .component("CloseCircleOutline", CloseCircleOutline)
  .component("Plus", Plus)
  .component("PlusCircleOutline", PlusCircleOutline)
  .component("Minus", Minus)
  .component("UserAccountCircle", UserAccountCircle)
  .component("MinusCircleOutline", MinusCircleOutline)
  .component("Menu", Menu)
  // .component("SwapHorizontalBold", SwapHorizontalBold)
  // .component("ArrowLeftBold", ArrowLeftBold)
  // .component("ArrowRightBold", ArrowRightBold)
  // .component("Silverware", Silverware)
  // .component("Cart", Cart)
  // .component("Tire", Tire)
  // .component("Autorenew", Autorenew)
  // .component("CreditCardOutline", CreditCardOutline)
  // .component("TableRow", TableRow)
  // .component("TableColumn", TableColumn)
  // .component("InformationOutline", InformationOutline)
  .component("CheckboxBlankOutline", CheckboxBlankOutline)
  .component("Stove", Stove)
  .component("Field", Field)
  .component("Form", Form)
  .component("ErrorMessage", ErrorMessage)
  .component("Food", Food)
  .component("FoodOutlined", FoodOutlined)
  .component("Car", Car)
  .component("AccountClockOutline", AccountClockOutline)
  .component("CheckboxMarkedOutline", CheckboxMarkedOutline)
  .component("BikeFast", BikeFast)
  .component("Bike", Bike)
  .component("LoadingSpinner", LoadingSpinner)
  .component("CreditCard", CreditCard)
  .component("Cash", Cash)
  .component("Play", Play)
  .component("Information", Information)
  .component("Pdf", Pdf)
  .component("RefundPartial", RefundPartial)
  .component("RefundFull", RefundFull)
  .component("ChevronUp", ChevronUp)
  .component("ChevronDown", ChevronDown)
  .component("ArrowRight", ArrowRight)
  .component("ArrowLeft", ArrowLeft)
  .component("Check", Check)
  .component("Close", Close)
  .component("StatusList", StatusList)
  .component("Phone", Phone)
  .component("Basket", Basket)
  .component("Person", Person)
  .component("List", List)
  .component("MapMarker", MapMarker)
  .component("Vendor", Vendor)
  .component("PartyPopper", PartyPopper)
  .component("HumanDolly", HumanDolly);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js", { scope: "/" });
}

// *********************************************************************************************
// Logging / DataDog
import {
  LogConfig,
  VueErrorHandler,
  getSessionId,
  getFingerprint,
} from "@tucktrucks/core";
window.sessionId = getSessionId();
window.fingerprint = getFingerprint();

let logConfig = LogConfig.configure().addSessionId(window.sessionId);

logConfig = logConfig.sendToDataDog({
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.VUE_APP_DATADOG_SITE,
  service: process.env.VUE_APP_DATADOG_SERVICE,
  forwardErrorsToLogs: true,
});

if (process.env.NODE_ENV == "development") {
  logConfig = logConfig.sendToConsole();
}

const log = logConfig.build();
window.log = log;

app.config.errorHandler = (err, vm, info) =>
  VueErrorHandler(log, err, vm, info);

store.dispatch("apiPublic/instantiateClient");
store.dispatch("apiPrivate/instantiateClient");

// *********************************************************************************************

app.mount("#app");
