export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        groupBy(array, key) {
          return array.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        },
      },
    });
  },
};
