<template>
  <span
    :aria-hidden="!title"
    :aria-label="title"
    class="material-design-icon image-icon"
    role="img"
    v-bind="$attrs"
    @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24">
      <path
        d="M19,19H5V5H15V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V11H19M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
    </svg>
  </span>
</template>

<script>
  export default {
    name: "ImageIcon",
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
    },
  };
</script>
