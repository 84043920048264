import { LocalDateTime, Duration } from "@js-joda/core";
import Enumerable from "linq";
import store from "@/store";
import { deliveryStatuses } from "@/constants/deliveryStatuses.ts";

export default {
  namespaced: true,

  state: {
    job: null,
    lastUpdated: null,
    cacheDuration: Duration.ofSeconds(4),
    deliveries: [],
    loading: false,
  },
  actions: {
    startTask({ commit }) {
      commit("START_TASK");
    },

    stopTask({ commit }) {
      commit("STOP_TASK");
    },

    cachedUpdate({ state, commit }) {
      if (state.loading) return;
      const now = LocalDateTime.now();
      const cacheUntil = this.lastUpdated?.plus(this.cacheDuration);

      if (cacheUntil == null || now.isAfter(cacheUntil)) {
        commit("UPDATE", now);
      }
    },

    forceUpdate({ commit }) {
      const now = LocalDateTime.now();

      commit("UPDATE", now);
    },

    updateDelivery({ commit }, newDelivery) {
      commit("UPDATE_DELIVERY", newDelivery);
    },
  },

  mutations: {
    START_TASK(state) {
      store.dispatch("deliveries/forceUpdate");

      state.job = setInterval(() => {
        store.dispatch("deliveries/cachedUpdate");
      }, 5000);
    },

    async UPDATE(state, now) {
      state.lastUpdated = now;
      state.loading = true;

      try {
        let eventId = await store.getters["today/eventId"];

        // get all deliver and order info for this event
        let response =
          await store.state.apiPrivate.client.endpoints.eventDeliveries.getEventDeliveries(
            eventId
          );

        let deliveries = Enumerable.from(response.data.data.deliveries)
          .orderBy((x) => x.timings.advertised.outboundDepartTime)
          .toArray();

        let orders = response.data.data.orders;

        // window.log.info(
        //   "[🚚] " +
        //     deliveries.length +
        //     " deliveries downloaded for event " +
        //     eventId
        // );

        // upsert deliveries to repo
        for (let delivery of response.data.data.deliveries) {
          store.dispatch("apiPrivateDeliveries/upsert", delivery);
        }

        // upsert orders and customers to repo
        for (let order of response.data.data.orders) {
          store.dispatch("apiPublicOrders/upsert", order);
          store.dispatch("apiPrivateCustomers/upsert", order.customer);
        }

        // upsert services to repo
        for (let service of response.data.data.services) {
          store.dispatch("apiPublicServices/upsert", service);
        }

        // upsert vendors to repo
        for (let vendor of response.data.data.vendors) {
          store.dispatch("apiPublicVendors/upsert", vendor);
        }

        // ➰ Loop through these and discover the additional context. Note, the repos take care of caching.
        for (let k = 0; k < deliveries.length; k++) {
          let validOrders = [];

          // ➰ Loop through the orders in a delivery, and discover the additional items.
          for (let i = 0; i < deliveries[k].orders.length; i++) {
            // ⬇️ Download the order info using the id.
            // let order = await store.getters["apiPublicOrders/getById"](
            //   deliveries[k].orders[i]
            // );

            let order = orders.find(({ id }) => id === deliveries[k].orders[i]);

            if (order?.status === "Cancelled") {
              continue;
            }

            if (order) {
              // ⬇️ If the customer id is set, download the info and attach it to the order
              if (order.customer.id != null && order.customer.id != 0) {
                let customer = await store.getters[
                  "apiPrivateCustomers/getById"
                ](order.customer.id);
                order.customer = customer;
              }

              // ⬇️ Download the service info and attach to the order.
              let service = await store.getters["apiPublicServices/getById"](
                order.serviceId
              );

              // ⬇️ Download the vendor info and attach to the service.
              let vendor = await store.getters["apiPublicVendors/getById"](
                service.vendorId
              );

              service.vendor = vendor;
              order.service = service;
              validOrders.push(order);
            }
          }

          deliveries[k].orders = validOrders;
        }

        const deliveryWithOrders = deliveries.filter(
          ({ orders }) => orders.length > 0
        );

        state.deliveries = deliveryWithOrders;
      } catch (error) {
        window.log.error(error);

        return;
      } finally {
        state.loading = false;
      }
    },

    STOP_TASK(state) {
      if (state.job != null) {
        clearInterval(state.job);
      }

      state.job = null;
    },
    UPDATE_DELIVERY(state, newDelivery) {
      state.deliveries = state.deliveries.map((delivery) => {
        if (delivery.id === newDelivery.id) {
          delivery.status = newDelivery.status;
        }
        return delivery;
      });
    },
  },

  getters: {
    get: (state) =>
      state.deliveries.filter(
        (delivery) => delivery.status !== deliveryStatuses.COMPLETED
      ),
  },
};
