import { parseLatLng } from "@tucktrucks/core";

export default {
  namespaced: true,

  state: {
    profile: "cycling",
    current: {},
  },

  actions: {
    async download({ commit, state }, downloadData) {
      const start = parseLatLng(downloadData.start);
      const end = parseLatLng(downloadData.end);

      if (!start || !end) return;

      const query = await fetch(
        `${process.env.VUE_APP_MAP_DIRECTION_REQUEST_URL}/${state.profile}/${start.lng},${start.lat};${end.lng},${end.lat}?geometries=geojson&access_token=${process.env.VUE_APP_MAP_ACCESS_TOKEN}`,
        { method: "GET" }
      );

      const json = await query.json();

      if (json.routes.length > 0) {
        const data = json.routes[0];
        const route = data.geometry.coordinates;

        const geojson = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: route,
          },
        };

        commit("SET_ROUTE", {
          id: downloadData.destinationId,
          start,
          end,
          raw: json,
          routes: data,
          route: route,
          geojson,
        });
      } else {
        commit("SET_ROUTE", {
          id: downloadData.destinationId,
          start,
          end,
          raw: json,
          routes: {},
          route: [],
          geojson: {},
        });
      }
    },
    updateRoute({ commit }, route) {
      commit("SET_ROUTE", route);
    },
  },

  mutations: {
    SET_ROUTE(state, route) {
      state.current = route;
    },
  },

  getters: {
    current: (state) => state.current,
  },
};
