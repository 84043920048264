import PrivateRepoStore from "./apiPrivateBaseStore.js";
import Enumerable from "linq";
import store from "@/store";
import { useToast } from "vue-toastification";

const deliveryRepoStore = new PrivateRepoStore("ApiDeliveryRepo", 2);

deliveryRepoStore.mutations.SELECT_CURRENT = (state, delivery) => {
  state.current = delivery;
};

deliveryRepoStore.actions.selectCurrent = ({ commit }, delivery) => {
  commit("SELECT_CURRENT", delivery);
};

deliveryRepoStore.actions.updateStatus = ({ commit }, payload) => {
  return store.state.apiPrivate.client.endpoints.deliveryStatus
    .update(payload.status, payload.id)
    .then((response) => {
      if (response.status == 200) {
        return response.data.data;
      }

      return Promise.reject("Failed to update delivery status.");
    })
    .then(() => {
      return store.getters["apiPrivateDeliveries/getById"](payload.id).then(
        (delivery) => {
          delivery.status = payload.status;
          commit("UPSERT", delivery);

          return delivery;
        }
      );
    })
    .catch((error) => {
      window.log.error(error);
      useToast().error(error);
    });
};

deliveryRepoStore.getters.getAll = (state) =>
  Enumerable.from(Object.values(state.repo.entities))
    .select((x) => x.data)
    .toArray();

deliveryRepoStore.getters.getCurrent = (state) => state.current;

deliveryRepoStore.getters.getByEvent = () => async (eventId) => {
  let response =
    await store.state.apiPrivate.client.endpoints.eventDeliveries.getEventDeliveries(
      eventId
    );

  if (response.status >= 200) {
    let data = response.data.data;

    Enumerable.from(data).select((delivery) => {
      store.dispatch("apiPrivateDeliveries/upsert", delivery);
    });

    // window.log.info(
    //   "[🚚] " + data.length + " deliveries downloaded for event " + eventId
    // );

    return data;
  }

  return [];
};

export default deliveryRepoStore;
