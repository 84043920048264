import {
  DateTimeFormatter,
  LocalDateTime,
  LocalTime,
  LocalDate,
} from "@js-joda/core";
require("@js-joda/timezone");
import store from "@/store";

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        getCdnFile(file) {
          return process.env.VUE_APP_CDN + file;
        },

        formatMoney(money) {
          if (money == null || isNaN(money)) {
            return "";
          }

          return new Intl.NumberFormat(store.state.locale, {
            style: "currency",
            currency: store.state.currency,
          }).format(money);
        },
        formatDateTime(dateTime, format = "dd/MM/yyyy HH:mm") {
          const formatter = DateTimeFormatter.ofPattern(format);
          const localDateTime = LocalDateTime.parse(dateTime);
          return localDateTime.format(formatter);
        },
        formatDate(date, format = "dd/MM/yyyy") {
          if (date == null) {
            return "--/--/----";
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localDate = LocalDate.parse(date);
          return localDate.format(formatter);
        },
        formatTime(time, format = "HH:mm") {
          if (time == null) {
            return "--:--";
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localTime = LocalTime.parse(time);
          return localTime.format(formatter);
        },
      },
    });
  },
};
