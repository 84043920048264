export const status = [
  "heading to pick-up location",
  "picking up orders",
  "heading to drop-off location",
  "handing off orders",
  "returning",
  "finished",
];

export const btnText = [
  "pick up orders",
  "head to drop-off",
  "start hand-off",
  "delivery return",
  "finished!",
];

export const journeyTypeOrder = [
  "to-pickup",
  "picking-up",
  "to-dropoff",
  "dropping-off",
  "return",
];
