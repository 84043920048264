<template>
  <div class="loading-spinner" :style="cssProps"></div>
</template>

<script>
  export default {
    props: {
      fontSize: {
        type: String,
        default: "inherit",
      },
      color: {
        type: String,
        default: "#ff7575",
      },
      verticalLayout: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      cssProps() {
        return {
          "--font-size": this.fontSize,
          "--color": this.color,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loading-spinner {
    /* Font size controls size of spinner */
    font-size: var(--font-size);
    border: 4px solid var(--color);
    display: inline-block;
    border-top-color: rgba(255, 255, 255, 0);
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    animation: loading-spinner 1s linear infinite;
    margin: -1rem auto;
    box-sizing: border-box;
    vertical-align: middle;
  }

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
