<template>
  <span
    :aria-hidden="!title"
    :aria-label="title"
    class="material-design-icon image-icon"
    role="img"
    v-bind="$attrs"
    @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24">
      <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
    </svg>
  </span>
</template>

<script>
  export default {
    name: "ImageIcon",
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
    },
  };
</script>
