import store from "@/store";

export default {
  namespaced: true,

  state: {
    eventId: 0,
  },

  actions: {
    setEventId({ commit }, eventId) {
      commit("SET_EVENT_ID", eventId);
    },

    async downloadTodaysEvent({ commit }) {
      let todayResponse =
        await store.state.apiPublic.client.endpoints.venues.getTodaysEvent(1);

      if (todayResponse.status != 200) {
        throw new Error("Could not determine todays event.");
      }

      store.dispatch("apiPublicEvents/upsert", todayResponse.data.data);

      commit("SET_EVENT_ID", todayResponse.data.data.id);

      window.log.info(
        `[🏕️] Event ${todayResponse.data.data.id} downloaded. (${todayResponse.data.data.date})`
      );
    },
  },

  mutations: {
    SET_EVENT_ID(state, eventId) {
      state.eventId = eventId;
    },
  },

  getters: {
    eventId: (state) => state.eventId,
    event: (state) => store.getters["apiPublicEvents/getById"](state.eventId),
  },
};
