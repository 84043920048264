<template>
  <span
    :aria-hidden="!title"
    :aria-label="title"
    class="material-design-icon image-icon"
    role="img"
    v-bind="$attrs"
    @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24">
      <path
        d="M18.06 23H19.72C20.56 23 21.25 22.35 21.35 21.53L23 5.05H18V1H16.03V5.05H11.06L11.36 7.39C13.07 7.86 14.67 8.71 15.63 9.65C17.07 11.07 18.06 12.54 18.06 14.94V23M1 22V21H16.03V22C16.03 22.54 15.58 23 15 23H2C1.45 23 1 22.54 1 22M16.03 15C16.03 7 1 7 1 15H16.03M1 17H16V19H1V17Z" />
    </svg>
  </span>
</template>

<script>
  export default {
    name: "ImageIcon",
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
    },
  };
</script>
