<template>
  <span
    :aria-hidden="!title"
    :aria-label="title"
    :title="title"
    class="mdi-icon"
    role="img"
    v-bind="$attrs"
    @click="$emit('click', $event)">
    <svg :fill="fillColor" :width="size" :height="size" viewBox="0 0 24 24">
      <path :d="svgPath" v-if="path != null" />
      <path :d="fallbackPath" v-else />
    </svg>
  </span>
</template>

<script>
  export default {
    name: "ImageIcon",
    data() {
      return {
        svgPath: this.path,
        fallbackPath: "M3,3V21H21V3",
      };
    },
    props: {
      path: {
        type: String,
      },
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
    },
  };
</script>

<style lang="scss">
  .mdi-icon {
    line-height: 0;

    svg {
      vertical-align: bottom;
    }
  }
</style>
