import PrivateRepoStore from "./apiPrivateBaseStore.js";
import Enumerable from "linq";

const customerRepoStore = new PrivateRepoStore("ApiCustomerRepo", 2);

customerRepoStore.getters.getAll = (state) =>
  Enumerable.from(Object.values(state.repo.entities))
    .select((x) => x.data)
    .toArray();

export default customerRepoStore;
