import PublicRepoStore from "./apiPublicBaseStore.js";

const orderRepo = new PublicRepoStore("ApiOrderRepo", 4);

orderRepo.actions.updateStatus = ({ commit, state }, payload) => {
  state.repo.getById(payload.id).then((item) => {
    item.status = payload.status;

    commit("UPSERT", item);
  });
};

orderRepo.getters.get = (state) => () => {
  return state.repo.get();
};

export default orderRepo;
