import { btnText, status, journeyTypeOrder } from "../../config/deliveryStatus";

export default {
  namespaced: true,

  state: {
    deliveryStatus: null,
    nextStepBtnText: null,
    journeyType: null,
  },

  mutations: {
    UPDATE_DELIVERY_NEXT_STATUS(state, payload) {
      state.deliveryStatus = payload.status;
      state.nextStepBtnText = payload.nextStepBtnText;
      state.journeyType = payload.journeyType;
    },
  },

  actions: {
    goToNextStep(context) {
      const currentStatus = context.state.deliveryStatus;
      const statusIndex = status.indexOf(currentStatus);

      if (statusIndex === 4) {
        return context.commit("UPDATE_DELIVERY_NEXT_STATUS", {
          status: status[statusIndex + 1],
        });
      }

      context.commit("UPDATE_DELIVERY_NEXT_STATUS", {
        status: status[statusIndex + 1],
        nextStepBtnText: btnText[statusIndex + 1],
        journeyType: journeyTypeOrder[statusIndex + 1],
      });
    },

    resetStatus(context) {
      context.commit("UPDATE_DELIVERY_NEXT_STATUS", {
        status: null,
        nextStepBtnText: null,
        journeyType: null,
      });
    },
  },
};
